.contactUsContainer{
    background-color: #f9fafb;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:15px;
}

.questionTitle{
    font-weight: 600;
    font-size: 1.2rem;
}

.questionText{
    color: #475467;
}