/* .card-container{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 8px;
  border-width: 10px;
  background-color: rgb(188, 181, 181);
} */

.card-container {
  /* width: 100%;
  height: 250px; /* Change height to auto for dynamic content */

  /* height: 100%; */
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  width: 99%;/* Add a subtle border */
  background-color: #f9fafb; /* Light background color for the card */
  transition: box-shadow 0.3s ease; /* Add transition effect for hover */
}