.parent {
    display: grid;
    grid-template-rows: auto 1fr 1fr; /* Three rows: auto for full width, 1fr for two equal-sized rows */
    gap: 10px; /* Gap between grid items */
  }
  

  .large_row{
    grid-column: span 2; /* Occupies 2 columns */

  }

  .equal_row{
    grid-row: span 1; /* Occupies 1 row */
  }



  @media screen and (max-width: 1220px) {
    .parent {
        display: grid;
        grid-template-rows: auto 1fr 1fr; /* Three rows: auto for full width, 1fr for two equal-sized rows */
        gap: 10px; /* Gap between grid items */
        
      }
      
    
      .large_row{
        grid-column: span 1; /* Occupies 2 columns */
    
      }
    
      .equal_row{
        grid-row: span 1; /* Occupies 1 row */
      }
    
  }
