[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: #000;
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #000;
  --amplify-components-tabs-item-active-border-color:#000;
  --amplify-components-tabs-item-color: #000;
  --amplify-components-tabs-item-active-color: #000;
  --amplify-components-button-link-color: #000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin:0;
}

:root {
  --bg:  #242526;
  --bg-accent: #484a4d;
  --text-color: #dadce1;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms; 
}


a {
  text-decoration: none !important;
}

.main-content {
  /* Your styles for main content here */
  flex: 1; /* This will make the content expand to fill the available space */
  padding: 30px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cardContainer{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Button{
  background-color: #000; /* Dark grey background */
  color: #fff; /* White text for contrast */
  border: 1px solid #444; /* Slightly lighter border for some depth */
  align-items: center;
  margin-right: auto;
  padding: 10px 20px; /* Top and bottom padding 10px, left and right 20px */
  font-size: 16px; /* Reasonable default font size */
  font-weight: bold; /* Bold text */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor indicates the element is clickable */
  outline: none; /* Remove outline - customize focus style below if needed */
}

.ButtonCenter{
  background-color: #000; /* Dark grey background */
  color: #fff; /* White text for contrast */
  border: 1px solid #444; /* Slightly lighter border for some depth */
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 20px; /* Top and bottom padding 10px, left and right 20px */
  font-size: 16px; /* Reasonable default font size */
  font-weight: bold; /* Bold text */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor indicates the element is clickable */
  outline: none; /* Remove outline - customize focus style below if needed */
}

.cardTitle{
  margin-left: auto;
  margin-right: auto;
  font-weight: bolder;
  font-size: 2rem;
}

.landingPage-SecondHalf{
  padding: 30px;
}
