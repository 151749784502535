.titleContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.titleHeader{
    font-weight:600;
    font-size: 2.5rem;
    text-align: center;
}

.titleContent{
    text-align: center;
    max-width: 50%;
}

.gif-container {
    position: relative;
    width: 100vw;
    height: calc(100vh - var(--nav-size)); 
    overflow: hidden;
  }
  
  .gif-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(1.5px);
  }
  
  .text-overlay {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 2; /* Higher z-index to ensure it's on top */
    color: white; /* Ensures text is visible against possibly dark GIFs */
    padding: 30px; /* Adds padding inside the overlay */
  }

  .transparent-button {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .transparent-button:hover {
    background-color: white;
    color: black;
  }
  
  
 