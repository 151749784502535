.footer-container{
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
}

.footer-text{
    color: #475467;
}

.footerLogo{
    margin-left: -60px;
    margin-right: -60px;
    width: 150px;
    height: var(--width);
}

.logoContainer{
    display: flex;
    gap: 5px;
    align-items: center;
}

.footer-links{
    padding-top: 5px;
    padding-bottom: 10px;
    margin-top: -60px;
    gap: 15px;
    display: flex;
}

.hr{
    background:#475467;
    height: 1px;
    margin-bottom: 5px;
}